import { Button, Form, Icon, Input, Modal, TextArea } from 'semantic-ui-react';
import React, { Component } from 'react';
import {
  clearWrite,
  editTask,
  writeTask,
  writeTaskDeadline,
} from '../../actions/homework';

import { DateTimeInput } from 'semantic-ui-calendar-react';
import { connect } from 'react-redux';
import moment from 'moment';

class EditTaskForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }

  render() {
    const { id, title, text, deadline, bits } = this.props.selectedTask;
    return (
      <Modal
        open={this.state.showModal}
        onOpen={this.props.onClick}
        closeOnDimmerClick
        onClose={() => this.setState({ showModal: false })}
        trigger={
          <Button
            inverted
            style={{ marginRight: '2em' }}
            color="orange"
            onClick={() => {
              this.setState({ showModal: true });
            }}
          >
            <Icon name="edit" /> Módosítás
          </Button>
        }
      >
        <Modal.Header>A{title} nevű feladat módosítása:</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field
              control={Input}
              label="Cím:"
              name="title"
              onChange={(e) => this.props.writeTask(e)}
              value={title}
              placeholder="Add meg a feladat címét."
            />
            <Form.Field
              control={TextArea}
              label="Leírás:"
              name="text"
              onChange={(e) => this.props.writeTask(e)}
              value={text}
              placeholder="Add meg a feladat leírását..."
            />
            <Form.Field
              control={DateTimeInput}
              label="Beadási határidő (a jelenlegi időnél későbbi időpont):"
              name="deadline"
              placeholder="Beadási határidő"
              iconPosition="left"
              dateTimeFormat="YYYY-MM-DDTHH:mm"
              onChange={(e, { name, value }) => {
                this.props.writeTaskDeadline({ name, value });
              }}
              value={deadline}
            />
            <Form.Field
              control={Input}
              type="number"
              label="Bitek száma:"
              name="bits"
              onChange={(e) => this.props.writeTask(e)}
              value={bits}
              placeholder="Add meg a feladatért kapható bitek számát ..."
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            inverted
            color="red"
            onClick={() => {
              this.setState({ showModal: false });
              this.props.clearWrite();
            }}
          >
            <Icon name="remove" /> Mégse
          </Button>
          <Button
            inverted
            color="green"
            disabled={
              title === '' ||
              (title !== undefined ? title.length > 150 : false) ||
              text === '' ||
              deadline === '' ||
              moment().isAfter(deadline)
            }
            onClick={() => {
              this.props.editTask({
                id,
                title,
                text,
                deadline,
                bits,
              });
              this.setState({ showModal: false });
              this.props.clearWrite();
            }}
          >
            <Icon name="checkmark" /> Módosítás
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ selectedTask, user }) => ({ selectedTask, user });

export default connect(mapStateToProps, {
  writeTask,
  writeTaskDeadline,
  editTask,
  clearWrite,
})(EditTaskForm);
