import { Container, Menu } from 'semantic-ui-react';
import React, { Component } from 'react';

import Events from './Events';
import LeaderBoard from './LeaderBoard';
import Presence from './Presence';

export default class Statistics extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { activeItem: 'events' };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { activeItem } = this.state;
    return (
      <div>
        <Container
          textAlign="center"
          style={{ paddingBottom: '5em', paddingTop: '1em' }}
        >
          <Menu tabular attached="top" size="huge" compact>
            <Menu.Item
              name="events"
              active={activeItem === 'events'}
              onClick={this.handleItemClick}
            >
              Alkalmak
            </Menu.Item>
            <Menu.Item
              name="presence"
              active={activeItem === 'presence'}
              onClick={this.handleItemClick}
            >
              Jelenlét
            </Menu.Item>
            <Menu.Item
              name="leaderboard"
              active={activeItem === 'leaderboard'}
              onClick={this.handleItemClick}
            >
              Ranglista
            </Menu.Item>
          </Menu>
          {activeItem === 'events' ? <Events /> : ''}
          {activeItem === 'presence' ? <Presence /> : ''}
          {activeItem === 'leaderboard' ? <LeaderBoard /> : ''}
        </Container>
      </div>
    );
  }
}
