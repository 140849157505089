import {
  ADD_NEWS,
  CLEAR_WRITE,
  DELETE_NEWS,
  EDIT_NEWS,
  GET_NEWS,
  SELECT_NEWS,
  WRITE_NEWS,
} from './types';

import axios from './session';

export const getNews = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/news');
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const postNews = ({ title, updated_by, text }) => async (dispatch) => {
  try {
    const response = await axios.post('/api/v1/news/', {
      updated_by,
      title,
      text,
    });
    if (response.data.id) {
      alert('Sikeres mentés!');
      dispatch({
        type: ADD_NEWS,
        payload: response.data,
      });
    } else {
      alert('Mentés nem sikerült!');
    }
  } catch (e) {
    console.log(e);
  }
};

export const editNews = ({ id, title, text, updated_by }) => async (
  dispatch
) => {
  try {
    const response = await axios.patch(`/api/v1/news/${id}/`, {
      updated_by,
      title,
      text,
    });
    if (response.data.id) {
      alert('Sikeres mentés!');
      dispatch({
        type: EDIT_NEWS,
        payload: response.data,
      });
    } else {
      alert('Mentés nem sikerült!');
    }
  } catch (e) {
    console.log(e);
  }
};

export const deleteNews = (news) => async (dispatch) => {
  try {
    const response = await axios.delete(`/api/v1/news/${news.id}/`);
    if (!response.data.id) {
      alert('Sikeres törlés!');
      dispatch({
        type: DELETE_NEWS,
        payload: news,
      });
    } else {
      alert('A törlés nem sikerült!');
    }
  } catch (e) {
    console.log(e);
  }
};

export const writeNews = ({ target: { name, value } }) => (dispatch) => {
  dispatch({ type: WRITE_NEWS, payload: value, target: name });
};

export const clearWrite = () => (dispatch) => {
  dispatch({ type: CLEAR_WRITE });
};

export const setSelectedNews = (item) => (dispatch) => {
  dispatch({ type: SELECT_NEWS, payload: item });
};
