import { Divider, Header, Segment } from 'semantic-ui-react';
import React, { Component } from 'react';

class GroupCard extends Component {
  render() {
    return (
      <Segment style={{ marginTop: 0 }}>
        <Divider style={{ fontSize: '2em' }} horizontal>
          <Header as="h3" style={{ fontSize: '1.2em' }}>
            {this.props.label}
          </Header>
        </Divider>
        <div
          className="paragraph"
          dangerouslySetInnerHTML={{ __html: this.props.value }}
        />
      </Segment>
    );
  }
}

export default GroupCard;
