import { Container, Segment } from "semantic-ui-react";

import React from "react";

const Footer = () => (
  <Segment inverted vertical textAlign="center">
    <Container>
      <p textalign="center">Created by DevTeam &copy; 2018-2025.</p>
    </Container>
  </Segment>
);

export default Footer;
