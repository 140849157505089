import "./Home.css";

import {
  Button,
  Container,
  Divider,
  Header,
  Icon,
  Image,
  Responsive,
  Segment,
} from "semantic-ui-react";
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from "react";

import KSZKbiglogo from "../images/kszk_with_shadow.png";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { connect } from "react-redux";
import { getImages } from "../../actions/home";

const settings = {
  dots: false,
  autoplay: true,
  arrows: false,
  infinite: true,
  speed: 2000,
  autoplaySpeed: 4000,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  lazyLoad: true,
  initialSlide: Math.floor(Math.random() * 32 + 1),
};

class Home extends Component {
  UNSAFE_componentWillMount() {
    this.props.getImages();
  }

  renderSlider(view, imageWidth) {
    if (view === 0) {
      return (
        <div>
          {!!this.props.images}
          <Slider {...settings}>
            {this.props.images &&
              this.props.images.map((image) => (
                <div key={image}>
                  <img src={image.image} width={imageWidth} alt="" />
                </div>
              ))}
          </Slider>
          <div className="car-text-kszk">
            <Segment textAlign="center" vertical>
              <Header
                as="h1"
                content="Üdvözlünk a"
                inverted
                style={{
                  fontSize: "3vw",
                  fontWeight: "normal",
                  marginBottom: 0,
                }}
              />
              <Header
                as="h1"
                content="Kollégiumi Számítástechnikai Kör"
                inverted
                style={{
                  fontSize: "3.5vw",
                  fontWeight: "bold",
                  marginBottom: "0.5vw",
                  marginTop: "0.5vw",
                }}
              />
              <Header
                as="h1"
                content="újoncképzésének weboldalán!"
                inverted
                style={{
                  fontSize: "3vw",
                  fontWeight: "normal",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                }}
              />
              <Image
                verticalAlign="middle"
                src={KSZKbiglogo}
                style={{ marginTop: "1vw", width: "17%" }}
              />
              <Header
                as="h1"
                content="Szeretettel várunk a KSZKépzésre!"
                inverted
                style={{
                  fontSize: "3vw",
                  fontWeight: "normal",
                  marginBottom: 0,
                  marginTop: "1vw",
                }}
              />
              <Container>
                {this.props.user.id ? (
                <Button
                as={Link}
                to="/profile"
                primary
                size="huge"
                style={{
                fontSize: "2vw",
                marginTop: "1vw",
                marginBottom: "1vw",
                fontWeight: "bold",
                letterSpacing: "0.13vw",
                }}
                >
                    Jelentkezés
                <Icon name="right arrow" />
                </Button>
                ) : (
                  <Button
                    href="/oidc/authenticate/"
                    primary
                    size="huge"
                    style={{
                      fontSize: "2vw",
                      marginTop: "1vw",
                      marginBottom: "1vw",
                      fontWeight: "bold",
                      letterSpacing: "0.13vw",
                    }}
                  >
                    Bejelentkezés
                    <Icon name="right arrow" />
                  </Button>
                )}
              </Container>
            </Segment>
          </div>
        </div>
      );
    }
    if (view === 1) {
      return (
        <div>
          <Slider {...settings}>
            {this.props.images.map((image) => (
              <div key={image}>
                <img src={image.image} width={imageWidth} alt="" />
              </div>
            ))}
          </Slider>
          <div className="car-text-kszk">
            <Segment textAlign="center" vertical>
              <Header
                as="h1"
                content="Üdvözlünk a"
                inverted
                style={{
                  fontSize: "5vw",
                  fontWeight: "bold",
                  marginBottom: 0,
                }}
              />
              <Header
                as="h1"
                content="Kollégiumi Számítástechnikai Kör"
                inverted
                style={{
                  fontSize: "6vw",
                  fontWeight: "bold",
                  marginBottom: "0.5vw",
                  marginTop: "0.5vw",
                }}
              />
              <Header
                as="h1"
                content="újoncképzésének weboldalán!"
                inverted
                style={{
                  fontSize: "5vw",
                  fontWeight: "bold",
                  marginBottom: 0,
                  marginTop: "0.5vw",
                }}
              />
              <Image
                className="kszklogo"
                verticalAlign="middle"
                src={KSZKbiglogo}
                style={{ marginTop: "1vw", width: "20%" }}
              />
              <Header
                as="h1"
                content="Szeretettel várunk a KSZKépzésre!"
                inverted
                style={{
                  fontSize: "5vw",
                  fontWeight: "bold",
                  marginBottom: 0,
                  marginTop: "1vw",
                }}
              />
              <Container>
                {this.props.user.id ? (
                  <Button
                    as={Link}
                    to="/profile"
                    primary
                    size="massive"
                    style={{
                      fontSize: "4vw",
                      marginTop: "1.5vw",
                      marginBottom: "1vw",
                      fontWeight: "bold",
                      letterSpacing: "0.15vw",
                    }}
                  >
                    Jelentkezés
                    <Icon name="right arrow" />
                  </Button>
                ) : (
                  <Button
                    href="/oidc/authenticate/"
                    primary
                    size="massive"
                    style={{
                      fontSize: "4vw",
                      marginTop: "1vw",
                      marginBottom: "1vw",
                      fontWeight: "bold",
                      letterSpacing: "0.15vw",
                    }}
                  >
                    Bejelentkezés
                    <Icon name="right arrow" />
                  </Button>
                )}
              </Container>
            </Segment>
          </div>
        </div>
      );
    }
  }

  // eslint-disable-next-line class-methods-use-this
  renderText() {
    const kszk_age = new Date().getFullYear() - 1976;
    return (
      <Segment style={{ padding: "1em 0em", fontFamily: "Arial" }} vertical>
        <Container text>
          <Segment inverted color="green" tertiary>
            <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
              Bemutatkozó videók
            </Header>
            <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
              A gólyakörtére készített videóinkat megnézhetitek a{" "}
              <a
                href="https://www.youtube.com/@kollegiumiszamitastechnika470/videos"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "white", textDecoration: "underline" }}
              >
                YouTube csatornánkon
              </a>
              !
            </p>
          </Segment>
          <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
            Kik is vagyunk mi?
          </Header>
          <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
            A Kollégiumi Számítástechnikai Kör az Egyetem legrégebben működő és
            legnagyobb aktív, informatikával foglalkozó öntevékeny
            csoportosulása, idén ünnepeljük {kszk_age}. születésnapunkat. A
            patinás név mögött vidám hangulatú, alkotó kedvű csapat rejlik, mely
            a Kar jó szakmai képességű, számítástechnika iránt kiemelten
            érdeklődő tagjaiból verbuválódott, és bővül évente új tehetségekkel,
            lelkes informatikusokkal, villamosmérnökökkel.
          </p>
          <Button as={Link} size="large" to="/groups">
            Ismerd meg a köreinket! <Icon name="right arrow" />
          </Button>
          <Divider as="h4" className="header" style={{ margin: "3em 0em" }} />
          <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
            Lehetőségek
          </Header>
          <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
            A KSZK a lehetőségek tárháza, a hely ahol Te – leendő mérnök –
            minden területen kipróbálhatod, továbbképezheted magad. Nálunk
            kibontakoztathatod kreativitásod, tapasztalatot, mérnöki szemléletet
            szerezhetsz, miközben az ún. soft skill-jeidet is fejlesztheted. Ha
            számodra a szakma hivatás, ha szeretsz új dolgokat alkotni vagy csak
            jó társaságra vágysz, a legjobb helyre kerültél. A reszort körei a
            szakma egy-egy meghatározó területével foglalkoznak a fejlesztés és
            üzemeltetés területén.
          </p>
          <Divider as="h4" className="header" style={{ margin: "3em 0em" }} />
          <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
            Miért jó KSZK-snak lenni?
          </Header>
          <div
            class="quote--container"
            style={{ fontSize: "1.5em", fontFamily: "Arial" }}
          >
            <p class="quote">
              Szerintem KSZK-snak jó lenni, mert rengeteget tanulok belőle.
              Gyakorlok, hallok újdonságokat, kipróbálhatom magam mindenféle
              szerepben, és közben még barátokat is szerzek. Ezek a barátságok,
              tapasztalatok egyébként később is nagyon jól jönnek, például a
              CV-mben egész erős fegyvertény, hogy ennyi mindent csináltam.
              Tényleg rengeteget kaptam a reszorttól, szakmailag és emberileg
              is. Ja, és szeretem, hogy a KSZK ilyen szabad, és abba kóstolok
              bele, amibe akarok.
            </p>
            <p class="quote--author">&ndash; Feri</p>
          </div>
          <div
            class="quote--container"
            style={{ fontSize: "1.5em", fontFamily: "Arial" }}
          >
            <p class="quote">
              A nulláról kezdtem a képzést és az egyetemi tanulmányaimat is,
              semmilyen infós tudásom nem volt, de rengeteget fejlődtem a
              KSZK-nak köszönhetően. Ezenkívül nem csak szakmai tudást
              szereztem, hanem egy fantasztikus közösség részévé is váltam.
            </p>
            <p class="quote--author">&ndash; Patrik</p>
          </div>
          <div
            class="quote--container"
            style={{ fontSize: "1.5em", fontFamily: "Arial" }}
          >
            <p class="quote">
              Amikor idejöttem egyetemre nem tudtam, hogy mennyire fogom
              megtalálni a helyem. Aztán, elkezdtem megismerkedni a kari
              közösséggel és barátokat szereztem, akik jelentős részét a
              KSZK-ban ismertem meg, vagy velem csatlakozott ide. Együtt
              foglalkozgatunk projektekkel, a Ház rendszereinek üzemeltetésével
              és egyéb nagyon érdekes ügyes-bajos dolgokkal. Úgy érzem, hogy a
              KSZK-ban igazán megtaláltam a helyem, mind emberileg, mind
              szakmailag.
            </p>
            <p class="quote--author">&ndash; Mike</p>
          </div>
          <div
            class="quote--container"
            style={{ fontSize: "1.5em", fontFamily: "Arial" }}
          >
            <p class="quote">
              Számtalan dologgal foglalkozunk, és tényleg azt csinálhatom, ami
              csak érdekel. Emellett a KSZK-t a szívügyemnek tekintem, szerintem
              a legjobb lehetőség a szakmai fejlődésen kívül életre szóló
              barátságok alakítására.
            </p>
            <p class="quote--author">&ndash; Blint</p>
          </div>
          <Divider as="h4" className="header" style={{ margin: "3em 0em" }} />
          <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
            Képzésünk
          </Header>
          <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
            Képzésünk végén Te is igazi KSZK-ssá válhatsz, hiszen rengeteg
            szakmai tudást igyekszünk átadni nektek. A képzésalkalmak rendkívül
            jó hangulatban telnek, és a szociális irányultságú foglalkozások
            alatt egy nagyon jó csapat kovácsolódik az érdeklődőkből. Az első
            képzés időpontja február 20. csütörtök 18:00, ettől kezdve pedig
            minden héten egy közösségi és számos szakmai alkalom során
            találkozunk. A képzésalkalmak után április 11-től 13-ig rendezünk
            tábort. Az közösségi alkalmakon és a táboron kötelező a részvétel.
          </p>
          <Divider as="h4" className="header" style={{ margin: "3em 0em" }} />
          <Segment inverted color="red" tertiary>
            <Header as="h3" style={{ fontSize: "2em", fontFamily: "Arial" }}>
              Korlátos férőhely
            </Header>
            <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
              Általánosságban elmondható, hogy a KSZK tagjai rengeteg dologgal
              foglalkoznak a hétköznapjaik során, hogy minden informatikai
              rendszer úgy működjön a kollégiumban, ahogy kell. Így van ez
              velünk képzőkkel is, emiatt a férőhelyek korlátosak.
            </p>
            <p style={{ fontSize: "1.33em", fontFamily: "Arial" }}>
              Sajnos előfordulhat olyan eset, hogy valaki már nem fér be a
              képzésre, ezért kérünk titeket, hogy ennek tudatában
              jelentkezzetek majd. Ilyen esetben reméljük ez nem szegi
              kedveteket és jövőre újra próbálkoztok majd!
            </p>
          </Segment>
        </Container>
      </Segment>
    );
  }

  render() {
    return (
      <div>
        <Responsive minWidth={600}>
          <div className="car-image-kszk">
            <div />
            {this.renderSlider(0, "120%")}
          </div>
          {this.renderText()}
        </Responsive>
        <Responsive maxWidth={600}>
          <div className="car-image-kszk">{this.renderSlider(1, "200%")}</div>
          {this.renderText()}
        </Responsive>
      </div>
    );
  }
}

const mapStateToProps = ({ user, images }) => ({
  user,
  images,
});

export default connect(mapStateToProps, { getImages })(Home);
