import {
  ADD_EVENT_NOTE,
  CLEAR_WRITE,
  GET_NOTES_BY_EVENT,
  WRITE_NOTE,
} from './types';

import axios from './session';

export const getNotesByEvent = (id) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/notes/', {
      params: { eventID: id },
    });
    dispatch({
      type: GET_NOTES_BY_EVENT,
      payload: response.data,
    });
  } catch (e) {
    console.log(e);
  }
};

export const writeNote = (event) => (dispatch) =>
  dispatch({ type: WRITE_NOTE, payload: event.target.value });

export const postEventNote = ({ eventid, userid, note }) => async (
  dispatch
) => {
  try {
    const response = await axios.post('/api/v1/notes/', {
      event: eventid || '',
      profile: userid || '',
      note,
    });
    if (response.data.id) {
      alert('Sikeres mentés!');
      dispatch({
        type: ADD_EVENT_NOTE,
        payload: response.data,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const clearWrite = () => (dispatch) => {
  dispatch({ type: CLEAR_WRITE });
};
