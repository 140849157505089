import "moment/locale/hu";

import {
  Accordion,
  Container,
  Grid,
  Header,
  Icon,
  Segment,
} from "semantic-ui-react";
import React, { Component } from "react";

import { connect } from "react-redux";
import { getStudentEvents } from "../../actions/statistics";
import moment from "moment";

class Schedule extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { activeIndex: 0 };

  UNSAFE_componentWillMount() {
    this.props.getStudentEvents();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;

    const { events } = this.props;
    const panels = events
      .sort((a, b) => a.date - b.date)
      .map((event) => (
        <Accordion key={Math.random()}>
          <Accordion.Title
            active={activeIndex === event.id}
            index={event.id}
            onClick={this.handleClick}
          >
            <h2>
              <Grid>
                <Grid.Column floated="left" width={5} textAlign="left">
                  <Icon name="calendar alternate outline" color="blue" />{" "}
                  {event.name}
                </Grid.Column>
                <Grid.Column floated="right" width={8} textAlign="right">
                  {moment(event.date).locale("hu").format("LLLL")}
                </Grid.Column>
              </Grid>
            </h2>
          </Accordion.Title>
          <Accordion.Content active={activeIndex === event.id}>
            <Segment textAlign="left" style={{ overflowWrap: "break-word" }}>
              {event.description}
            </Segment>
          </Accordion.Content>
        </Accordion>
      ));

    return (
      <div>
        <Container textAlign="center">
          <Header
            as="h1"
            content="Képzés alkalmak"
            style={{
              fontSize: "2em",
              fontWeight: "bold",
              marginBottom: "0.5em",
              marginTop: "0.5em",
            }}
          />
          <Accordion fluid styled>
            {panels}
          </Accordion>
          <Header
            as="h1"
            content="Tábor: április 11-13"
            style={{
              fontSize: "2em",
              fontWeight: "bold",
              marginBottom: "0.5em",
              marginTop: "1.5em",
            }}
          />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = ({ events: { events }, user }) => ({ events, user });

export default connect(mapStateToProps, { getStudentEvents })(Schedule);
