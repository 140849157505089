import './Forms.css';

import {
  Button,
  Dimmer,
  Divider,
  Form,
  Header,
  Icon,
  Input,
  Loader,
  Modal,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import React, { Component } from 'react';
import {
  addDocument,
  addSolution,
  clearWrite,
  getDocuments,
  getSolutions,
  writeSolution,
  writeSolutionFile,
} from '../../actions/homework';

import ConfirmModal from './ConfirmModal';
import { connect } from 'react-redux';
import { customMessage } from '../pages/Homework';

const allowedFileTypes = [
  'image/jpeg',
  'image/png',
  'application/zip',
  'application/x-zip',
];

const allowedFileEnds = ['.zip', '.jpeg', '.jpg', '.jpe', '.png'];

function validateFileName(fileNameToValidate) {
  return (
    allowedFileEnds.find((typeName) => {
      return fileNameToValidate.toLowerCase().endsWith(typeName);
    }).length > 0
  );
}

// in megabytes
const maxFileSize = 50;

class AddSolutionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    };
  }

  render() {
    const { name, description, file } = this.props.newSolution;

    const task = this.props.taskid;

    const thisTaskSolution = this.props.homeworks.solutions.filter(
      (solution) => solution.task === task
    );
    const thisTaskDocument = this.props.homeworks.documents.filter(
      (document) => document.solution === thisTaskSolution[0]?.id
    );

    const lastName = thisTaskDocument[0]?.name;
    const lastDesc = thisTaskDocument[0]?.description;
    const lastFile = thisTaskDocument[0]?.file_url;

    const corrected = false;
    const accepted = false;
    const sentences = this.props.taskdesc.split('\n');
    const note = '';
    const disabledText = 'A határidő lejárt, további beadás nem lehetséges.';

    return (
      <Modal
        open={this.state.showModal}
        closeOnDimmerClick
        onClose={() => this.setState({ showModal: false })}
        trigger={
          <button
            type="button"
            id="task"
            onClick={() => {
              this.setState({ showModal: true });
            }}
          >
            <Icon name="external" />
            {this.props.tasktitle}
          </button>
        }
      >
        <Modal.Header>
          {this.props.multiple ? 'Másik megoldás' : 'Megoldás'} beadása a(z)
          {this.props.tasktitle} nevű feladathoz:
        </Modal.Header>
        <Modal.Content>
          <Modal.Description style={{ marginBottom: '2em' }}>
            <Header as="h5">Feladat leírása:</Header>
            {sentences.map((s) => (
              <p key={Math.random()}>{s}</p>
            ))}
          </Modal.Description>
          {this.props.disabled ? (
            <div>
              {lastName ? (
                <div style={{ paddingBottom: '1em' }}>
                  <div style={{ marginBottom: '1em', fontWeight: 'bold' }}>
                    Legutóbbi megoldásod:
                  </div>
                  <Segment attached="top">
                    <h5 style={{ paddingBottom: '0.4em' }}>Cím:</h5>
                    {lastName}
                  </Segment>
                  <Segment attached>
                    <h5 style={{ paddingBottom: '0.4em' }}>Leírás:</h5>
                    {lastDesc}
                  </Segment>
                  <Segment attached="bottom">
                    <h5>Beadott fájl:</h5>
                    {lastFile ? (
                      <a href={lastFile} rel="noreferrer noopener" download>
                        Fájl letöltése
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </Segment>
                </div>
              ) : (
                customMessage(
                  disabledText,
                  undefined,
                  undefined,
                  this.props.disabled
                )
              )}
            </div>
          ) : (
            <Form>
              {lastName ? (
                <div style={{ paddingBottom: '1em' }}>
                  <div style={{ fontWeight: 'bold' }}>
                    Legutóbbi megoldásod:
                  </div>
                  <Segment attached="top">
                    <h5 style={{ paddingBottom: '0.4em' }}>Cím:</h5>
                    {lastName}
                  </Segment>
                  <Segment attached>
                    <h5 style={{ paddingBottom: '0.4em' }}>Leírás:</h5>
                    {lastDesc}
                  </Segment>
                  <Segment attached="bottom">
                    <h5>Beadott fájl:</h5>
                    {lastFile ? (
                      <a href={lastFile} rel="noreferrer noopener" download>
                        Fájl letöltése
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </Segment>
                </div>
              ) : null}
              <Divider />
              <Form.Field
                control={Input}
                label="Megoldás címe:"
                name="name"
                onChange={(e) => this.props.writeSolution(e)}
                value={name}
                placeholder="Adj meg egy címet a beadandó megoldásodnak..."
              />
              <Form.Field
                control={TextArea}
                label="Megoldás leírása:"
                name="description"
                onChange={(e) => this.props.writeSolution(e)}
                value={description}
                placeholder="Add meg a megoldás leírását..."
              />
              <Form.Field>
                <label>
                  Fájl (Megengedett fájltípusok: png, jpeg, jpg, zip. Maximum 50
                  MB.):
                </label>
                <Input
                  type="file"
                  accept={allowedFileTypes.join(',')}
                  onChange={(e) => this.props.writeSolutionFile(e)}
                />
              </Form.Field>
            </Form>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            inverted
            color="red"
            onClick={() => {
              this.setState({ showModal: false });
              this.props.clearWrite();
            }}
          >
            <Icon name="remove" /> Mégse
          </Button>
          {this.props.multiple ? (
            <ConfirmModal
              button={
                <Button
                  disabled={
                    !name ||
                    !description ||
                    (!file
                      ? false
                      : !validateFileName(file.name) ||
                        file.size > maxFileSize * 1024 ** 2)
                  }
                  inverted
                  color="green"
                >
                  <Icon name="checkmark" />
                  Beadás
                  {this.state.loading ? (
                    <Dimmer active>
                      <Loader size="massive" />
                    </Dimmer>
                  ) : null}
                </Button>
              }
              text="beadod az új megoldást, ami felülírja az előzőt"
              onAccept={() => {
                this.setState({
                  loading: true,
                });
                this.props
                  .addSolution({
                    task,
                    accepted,
                    corrected,
                    note,
                    name,
                    description,
                    file,
                  })
                  .then(
                    () => {
                      this.setState({
                        loading: false,
                        showModal: false,
                      });
                      this.props.clearWrite();
                    },
                    () => {
                      this.setState({
                        loading: false,
                      });
                      alert('Sikertelen feltöltés!');
                    }
                  )
                  .finally(() => {
                    window.location.reload(false);
                  });
              }}
            />
          ) : (
            <Button
              inverted
              color="green"
              disabled={
                !name ||
                !description ||
                (!file
                  ? false
                  : !validateFileName(file.name) ||
                    file.size > maxFileSize * 1024 ** 2)
              }
              onClick={() => {
                this.props.addSolution({
                  task,
                  accepted,
                  corrected,
                  note,
                  name,
                  description,
                  file,
                });
                this.setState({ showModal: false });
                this.props.clearWrite();
              }}
            >
              <Icon name="checkmark" /> Beadás
            </Button>
          )}
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ newSolution, homeworks, user }) => ({
  newSolution,
  homeworks,
  user,
});

export default connect(mapStateToProps, {
  addSolution,
  writeSolution,
  writeSolutionFile,
  addDocument,
  getDocuments,
  clearWrite,
  getSolutions,
})(AddSolutionForm);
